.bee-animated{
    margin-right: auto;
    margin-left: auto;
    display: block;
    text-align: center;
}

.bee-animated.loading{
    position: relative;
    top: 18em;
    right: 15px;
    z-index: 999;
    animation: orbit 4s linear infinite;
}

.bee-animated.result{
    position: relative;
    left: 10px;
    animation: orbit-small 4s linear infinite;
}

.bee-body{
    position: absolute;
    top: 15px;
}

.bee-left-wing{
    position: absolute;
    top: 15px;
    animation: buzz 2ms linear infinite;

}
.bee-right-wing{
    position: absolute;
    top: 15px;
    animation: buzz 1ms linear infinite;
}

@keyframes buzz {
    0% { transform:rotate(3deg); }
    100% { transform:rotate(-3deg); }
}

@keyframes move {
    0% {
        offset-distance: 0px;
    }
    100% {
        offset-distance: 100px;
    }
}

@keyframes orbit {
    from   { transform: rotate(360deg) translateX(15px) rotate(-360deg); }
    to { transform: rotate(0deg) translateX(15px) rotate(0deg); }
}
@keyframes orbit-small {
    from   { transform: rotate(360deg) translateX(5px) rotate(-360deg); }
    to { transform: rotate(0deg) translateX(5px) rotate(0deg); }
}
