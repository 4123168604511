@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 100;}
}

.loading-page {
    margin-left: auto;
    margin-right: auto;
    width: 375px;
    height: 812px;
    opacity: 0;

    animation-fill-mode: forwards;
    animation-name: fade-in;
    animation-duration: 0.7s;
}

.bee.loading {
    position: relative;
    top: 19em;
}

.loader-text {
    position: relative;
    top: 11em;

    font-family: 'NunitoSans';
    font-weight: 800;
    font-size: 34px;
    line-height: 115.5%; /* or 39px */

    text-align: center;
    color: #FFFFFF;
}

.loading-background-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 0;
}
