.read-more-button {
    cursor: pointer;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.read-more-button.expanded {
    position: relative;
    top: -17.5vh;
    display: none;
}

.more-options {
    display: none;
    cursor: pointer;
    text-align: center;
}
.more-options.expanded {
    position: relative;
    top: -2vh;
    display: block;
}

.read-more-text {
    position: relative;
    top: 29px;
    margin-right: auto;
    margin-left: auto;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
}

.hidden {
    display: none;
}

/* Mobile only media query*/
@media only screen and (max-width: 833px) {
    /* CSS styles for mobile */
    .more-options.expanded{
        position: relative;
        top: -18vh;
        display: block;
    }
}
