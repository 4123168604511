.progress-arrow {
    position: relative;
    top: -5px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    text-align: center;
    fill: #ffffff4d;
}

.progress-arrow svg g path {
    fill: #ffffff4d;

}

.progress-arrow.selected svg g path {
    fill: #FFFFFF;
}
