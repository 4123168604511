.selection-button {
    position: relative;
    top: 11px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-bottom: -13px;
    color: aqua;
    grid-column: 2 / 4;
}

.selection-button svg {
    max-width: calc(100% - 5px);
    cursor: pointer;
}

/* This order is important base > hover > selected */
.red.selection-button svg path {
    fill: #C91D2F;
}

.red.selection-button:hover svg path {
    fill: #B51A2A;
}

.red.selected.selection-button svg path {
    fill: #7e101c;
}

/* This order is important base > hover > selected */
.blue.selection-button svg path {
    fill: #0E78B1;
}

.blue.selection-button:hover svg path {
    fill: #0B6DA0;
}

.blue.selected.selection-button svg path {
    fill: #094a6c;
}

.selection-button-text {
    position: relative;
    top: 37px;

    display: block;
    text-align: center;

    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 89.5%;
    color: #FFFFFF;

    cursor: pointer;
}
