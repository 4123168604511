.MapContainer{
    width: 100vw;
    height: 100vh;
}

button.back.map{
    position: fixed;
    top: 1rem;
    right: 5vh;
    z-index: 999;
    background-color: transparent;
    border: none;

    background-image: url('../Images/UI/close-x.png');
    width: 33px;
    height: 33px;
    background-repeat: no-repeat;
    cursor: pointer;
}


/*
.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    width: 100%;
    height: 100vh;
}

.pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: #0E78B1;
}



.MapContainer{
    width: 50vw;
    height: 100vh;
}

.leaftlet-container{
    width: 100vw;
    height: 100vh;
}

.pin-icon {
    font-size: 50px;
}

.pin-text {
    font-size: 15px;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 100vh;
    }

    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }

    .pin {
        width: 15vw;
    }

    .pin-icon {
        font-size: 45px;
    }
}

 */