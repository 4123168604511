.background-wrapper{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin: 0;
}

.question-text {
    opacity: 0;
    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 50px;
    
    text-align: center;

    color: #FFFFFF;
}

.question-text.active {
    animation-fill-mode: forwards;
    animation-name: fade-in;
    animation-duration: 0.7s;
}

@media (min-width: 2100px )  {
    .question2background{
        transform:scale(1);
        object-position: top left;
    }

    .question3background {
        transform:scale(1);
        object-position: top left;
    }

    .question4background {
        transform:scale(1);
        object-position: top left;
    }

    .question-text {
        position: absolute;
        bottom: 75vh;
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 833px ) and (max-width: 2099px) {
    .question2background{
        transform:scale(0.5);
        translate: -28% -40%;
        object-position: left center;
    }

    .question3background {
        /* “He who fights with monsters might take care lest he thereby become a monster. And if you gaze for long into an abyss, the abyss gazes also into you.” */
        width: 800% !important;
        height: 800% !important;
        transform:scale(0.3);
        translate: -44% -35%;
        object-position: top center;
    }

    .question4background {
        transform:scale(1);
        translate: -37% -45%;
        object-position: center center;
    }

    .question-text {
        position: absolute;
        bottom: 75vh;
        width: 100%;
        text-align: center;
    }
}


@media (max-width: 833px) {
    .question2background {
        transform:scale(0.3);
        translate: -40% -43%;
        object-position: left center;
    }

    .question3background {
        transform:scale(0.3);
        translate: -41% -35%;
        object-position: top center;
    }

    .question4background {
        transform:scale(0.35);
        translate: -40% -50%;
        object-position: center center;
    }

    .question-text {
        position: absolute;
        bottom: 74vh;
        width: 100%;
        text-align: center;

        font-size: 30px;
        line-height: 34.65px;
    }
}
