
.time-marker{
    height: 150px;
    width: 100px;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-23rem, -8rem);
}

.time-marker-text{
    position: relative;
    top: 55px;
    left: 35px;
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    color: #FFFFFF;
}

/*Mobile only media query*/
@media only screen and (max-width: 767px) {
    /*CSS styles for mobile*/
    .time-marker{
        height: 150px;
        width: 100px;
        position: absolute;
        display: block;
        top: 45%;
        left: 23rem;
        transform: translate(-23rem, -8rem);
    }
}
