@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 100}
}

@keyframes red-blue {
    from {background-color: #E22236;}
    to {background-color: #118ACB}
}

@keyframes blue-red {
    from {background-color: #118ACB}
    to {background-color: #E22236;}
}

@keyframes bounce {
    70% { transform:translateY(0%); }
    80% { transform:translateY(-25%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-15%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-6%); }
    100% { transform:translateY(0); }
}

.slider-frame {
    position: absolute;
    bottom: 0;
    height: 19vh;
    background-color: #E22236;
    border-radius: 90px     90px      0           0;
}

.slider-frame.slide-up {
    animation-name: slider-up;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
}

.slider-frame.red {
    background-color: #E22236;
}

.slider-frame.blue {
    background-color: #118ACB;
}

.selection-block {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

}

.progression-container {
    opacity: 0;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    width: 5.7rem;
    display: grid;

    grid-template-columns: repeat(4, 1fr);

}
.progression-container.active {
    animation-fill-mode: forwards;
    animation-name: fade-in;
    animation-duration: 0.7s;
}

.progression-info-text {
    opacity: 100;
    position: relative;
    top: -5%;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14.8px;
    line-height: 100%;
    color: #FFFFFF;
    margin-bottom: 15px;

    grid-column: 1 / 5;
    grid-row: 2 / 3;
}

.progression-info-text.active {
    animation-fill-mode: forwards;
    animation-name: fade-in;
    animation-duration: 0.7s;
}

.progression-info-text.warning {
    animation: bounce 1.5s ease 3;
}

.question-image {
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: 0;
}


.slider-content-wrapper {
    display: none;
}

.slider-content-wrapper.active {
    display: block;
}




.next-button{
    grid-column: 3 / 4;
    grid-row: 4 / 5;
    display: block;
    margin-right: auto;
    left: 4px;
}

.next-button.center_cta{
    grid-column: 2 / 4;
    grid-row: 4 / 5;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.back-button{
    display: none;
}


@media (max-width: 833px) {
    @keyframes slider-up {
        from {
            height: 8vh
        }
        to {
            height: 69vh;
        }
    }

    .slider-frame {
        width: 100%;
    }

    .slider-frame.active{
        height: 69vh;
    }

    .selection-block{
    }

    .cta-button{
        top: 8vh;
    }
}

@media (min-width: 833px) {
    @keyframes slider-up {
        from {
            height: 19vh
        }
        to {
            height: 65vh;
        }
    }
    .slider-frame {
        width: 50%;
        left: 25%;
    }

    .slider-frame.active{
        height: 66vh;
    }


    .selection-block{
        top: 0vh;
    }

    .back-button{
        grid-column: 2 / 3;
        grid-row: 4 / 5;
        display: block;
        margin-left: auto;
        right: 4px;

    }
}

@media (max-width: 833px) {
    .next-button{
        grid-column: 2 / 4;
        grid-row: 4 / 5;
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 833px) and (max-height: 730px) {
    .cta-button{
        top: 0vh;
    }
}

@media (min-width: 833px) and (max-height: 780px) {
    .cta-button{
        top: 4vh;
    }
}

@media (min-width: 833px) and (min-height: 781px) {
    .cta-button{
        top: 8vh;
    }
}
