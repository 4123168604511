.cta-button {
    cursor: pointer;
    position: relative;
    margin-bottom: 10vh;
}

.red.cta-button svg path{ 
    fill: #C91D2F;
}

.blue.cta-button svg path {
    fill: #0E78B1;
}

.cta-button-text {
    position: relative;
    top: 25px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    text-align: center;

    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 89.5%;

    color: #FFFFFF;
}

.cta-button.red:hover > svg > path{
    fill: #B51A2A;
}
.cta-button.blue:hover > svg > path{
    fill: #0B6DA0;
}