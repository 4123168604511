.map-cta {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    cursor: pointer;
    grid-column: 1/-1;
}

.map-icon {
    position: relative;
    top:15px;
    height: 30px;
}
.map-label {
    position: relative;
    top: 5px;
    left: 8px;
    color: #FFFFFF;
    font-family: 'NunitoSans', sans-serif;
}
