.results-view {
    background-color: #01526B;
    opacity: 100;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: grid;
    overflow: hidden;
    position: relative;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
}

.result-title {
    grid-column: 1 / -1;
    z-index: 1;
    padding-top: 6vh;

    font-family: 'NunitoSans', sans-serif;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.booking-bee {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 60px;
    right: 8vw;
    transition: all 0.7s ease;
}

.booking-bee.scrolling {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 190px;
    right: -66vw;
    transition: all .4s ease;
}

.bee-banner {
    position: relative;
    top: 25px;
    left: -140px;
}

@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 100;}
}


/* We set those rules for every display because the logo is hidden when not needed */
.logo-main-mobile{
    margin-top: 6vh;
    text-align: center;
    grid-column: 1 / -1;
}


/* Mobile only media query*/
@media (min-width: 834px) and (min-height: 730px) {
    .logo-main-mobile{
        display: none;
    }
}

@media (max-width: 833px) {
    .results-view {
        height: 100%;
    }
}


/* Desktop only media query */
/* @media only screen and (min-width: 1024px) {

} */
