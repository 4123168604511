/*body {margin: 0 auto; width: 100%; background-color: #01526B;}*/
html.no-scroll, body.no-scroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
    background-color: #01526B;
}

.landing-scroll-fix {
    position: fixed;
    width: 100vw;
}

@media (max-width: 832px) {
    .mobile-hidden {
        display: none;
    }
}

@media (min-width: 833px) and (max-height: 730px) {
    .mobile-hidden {
        display: none;
    }
}

.main-logo {
    position: absolute;
    top: 5vh;
    left: 3.33vw;
    z-index: 2;
}
