@keyframes fade-out {
    from {opacity: 100;}
    to {opacity: 0}
}

.background-cover {
    position:absolute;
    top: 0; left: 0;
    width: 500%;
    height: 500%;
    object-fit: none;
    overflow: visible;
}

@media (min-width: 833px) {
    .landing-view-background {
        transform:scale(0.6);
        translate: -43% -42%;
        object-position: center center;
    }
}


@media (max-width: 833px) {
    .landing-view-background {
        transform:scale(0.6);
        translate: -47% -42%;
        object-position: center center;
    }
}


.hero-logo-moretoexplore.initiate{
    position: relative;
    top: 35vh;
    max-width: 31%;
    text-align:center;
    display:block;
    margin-left: auto;
    margin-right: auto;

}

.hero-title {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    top: 36.6vh;

    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 95.5%; /* or 26px */
    
    text-align: center;

    color: #FFFFFF;
}

.hero-subtitle {
    position: relative;
    margin-right: auto;
    margin-left: auto;
    top: 38.3vh;

    font-family: 'NunitoSans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 115.5%; /* or 20px */
    
    text-align: center;

    color: #FFFFFF;

}

/* CSS styles for mobile */
@media only screen and (max-width: 1023px) {
    .landing {
        animation-name: fade-out;
        animation-duration: 0.6s;
        animation-fill-mode: forwards;
    }

    .hero-logo-main {
        text-align:center;
        display:block;
        margin:auto;
        position: relative;
        top: 1vh;
    }
}

/* CSS styles for desktop */
@media only screen and (min-width: 1024px) {
    .hero-logo-main {
        display:block;
        position: fixed;
        top: 6vh;
        left: 3vh;
    }
}
