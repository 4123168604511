.footer {
    width: 100%;
    background: #002935;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 1/-1;
}

.footer-title{
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 115.5%;;
    /* or 25px */

    text-align: center;
    color: #FFFFFF;
    flex:0 0 auto;
    
    margin-bottom: 2rem;
}

.footer-cta{
    text-align: center;
}

.social-button {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: inherit;
    font-family: inherit;

    cursor: pointer;
}


/*Mobile only media query*/
@media only screen and (max-width: 833px) {
    /*CSS styles for mobile*/

    .footer{
        width: 100%;
        background: #002935;
        bottom: 0%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        padding-bottom: 2.5rem;
    }

    .footer.result-page{
        position: relative;
    }

    .social-icons{
        margin-top: 2rem;
        grid-column: 2 / 6;
        grid-row: 2 / 3;
        text-align: center;
    }

    .social-icons svg{
        margin: 0 1.4rem;

    }

    .footer-cta-wrapper{
        margin-top: 2rem;
        grid-column: 2 / 6;
        grid-row: 1 / 2;
    }

    .footer-logo-main-mobile{
        text-align:center;
        display:block;
        margin-top: 2rem;
        grid-column: 2 / 6;
        grid-row: 3 / 4;
    }
}


@media (min-width: 834px) {
    .footer{
        width: 100%;
        background: #002935;
        bottom: 0%;
        padding-bottom: 1rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .footer.result-page{
        position: relative;
    }

    .social-icons{
        margin-top: 4.5rem;
        margin-right: max(50px, 10%);
        grid-column: 3 / 4;
        grid-row: 1 / 2;
    }

    .social-icons svg{
        /* margin: 5%; */
        margin-right: 31px;
    }

    .footer-cta-wrapper{
        margin-top: 1rem;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }

    .footer-logo-main-mobile{
        display:none;
    }
}



